<template>
  <div class="finish-lq">
    <div class="image-container">
      <div class="image-content">
        <div class="title">איזה כיף, עשית את הצעד הראשון בדרך לצמיחה כלכלית!</div>
        <div class="subtitle">בשלב הבא, נצרף אותך לשירות ונתחיל לבנות לך את התזרים החודשי שלך</div>
      </div>
      <lottie-player
          class="player"
          src="/animations/rising-sun" loop
          :autoplay="true"
          :preserveAspectRatio="'xMinYMax'"/>
    </div>
    <div class="button-container">
      <riseup-button class="next-question" :title="'להצטרפות לשירות'" :size="'small'" :action="gotToSignupDetails" />
    </div>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import router from '../../../../../router';
import Segment from '../../../../../Segment';

export default {
  name: 'FinishLQ',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  async created() {
    Segment.trackUserGot('FirstOnboardingPhaseDoneEntered');
    Segment.trackUserGot('FinishWineAndDine_Entered_Marketing');
  },
  methods: {
    gotToSignupDetails() {
      Segment.trackUserInteraction('FinishWineAndDine_NextClicked_Marketing');
      router.push({ path: '/signup/details/v3', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';

  .finish-lq {
    @include white-page-frame;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .image-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      background-color: $riseup_dark_yellow;

      .image-content {
        color: $riseup_white;
        @include responsive-padding;
        padding-top: 60px;
        text-align: center;

        .title {
          font-size: 22px;
          font-weight: bold;
          line-height: 1.27;
          margin-bottom: 20px;
        }

        .subtitle {
          font-size: 18px;
          line-height: 1.33;
        }
      }

      .player {
        height: 250px;
        width: 100%;
        background-color: $riseup_dark_yellow;
      }
    }

    .button-container {
      @include responsive-padding;
      @include responsive-padding-top-bottom;
      width: 100%;

      .next-question {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

</style>
