<template>
  <div class="blocked-cashflow-view">
    <div class="blocked-cashflow-view-container">
      <cashflow-type-base-summary-amount :blocked="true" class="card-wrapper"/>
      <cashflow-type-base-monthly-goal :blocked="true" class="card-wrapper"/>
      <cashflow-type-base-variable-expense :time-frame="'weekly'" :blocked="true" class="card-wrapper"/>
      <cashflow-type-base-economy :blocked="true" class="card-wrapper"/>
      <cashflow-type-base-fixed-expense :blocked="true" class="card-wrapper"/>
      <cashflow-type-base-income :blocked="true" class="card-wrapper"/>
    </div>
  </div>
</template>

<script>
import Segment from '../../../../../../../Segment';
import CashflowTypeBaseEconomy from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseEconomy';
import CashflowTypeBaseFixedExpense from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseFixedExpense';
import CashflowTypeBaseIncome from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseIncome';
import CashflowTypeBaseVariableExpense from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseVariableExpense';
import CashflowTypeBaseMonthlyGoal from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseMonthlyGoal';
import CashflowTypeBaseSummaryAmount from '../generic-components/cashflow-types/cashflow-types-base/CashflowTypeBaseSummaryAmount';

export default {
  name: 'BlockedCashflowView',
  components: {
    CashflowTypeBaseEconomy,
    CashflowTypeBaseFixedExpense,
    CashflowTypeBaseIncome,
    CashflowTypeBaseVariableExpense,
    CashflowTypeBaseMonthlyGoal,
    CashflowTypeBaseSummaryAmount,
  },
  async created() {
    Segment.trackUserGot('BlockedCashflowViewEntered');
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../scss/mixins';

  .blocked-cashflow-view {
    opacity: 0.15;

    .blocked-cashflow-view-container {
      @include responsive-padding;
      font-family: SimplerPro;
      text-align: right;
      width: 100%;
      margin: 0 auto;

      .card-wrapper {
        @include cashflow-card-margin;
      }
    }
  }
</style>
